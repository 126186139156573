import React, { useEffect } from "react";
import MobileNavbar from "components/MobileNavbar";
import AdminSideBar from "components/AdminSideBar";
import Bread from "components/Bread";
import ExtraBold from "components/ExtraBold";
import IndividualInputForm2 from "components/IndividualInputForm2";
import { Helmet } from "react-helmet";
import "assets/css/InputForm.css";
import "assets/css/Individual.css";
import ServerError from "../participationMenu/ServerError";
import { useParticipation } from "hooks/useParticipation";
import { useVerify } from "hooks/useVerify";

function AdminIndividualAddParticipation() {
  const { verifyToken } = useVerify();
  const { serverError } = useParticipation();

  useEffect(() => {
    verifyToken();
  }, []);

  if (serverError) {
    return (
      <div>
        <MobileNavbar />
        <Helmet>
          <title>ycf - 개인참가신청</title>
        </Helmet>
        <AdminSideBar />
        <div className="my-container">
          <div className="items item1">
            <Bread first="참가신청" second={"개인참가신청"} />
            <ExtraBold name="개인참가신청" />
          </div>
        </div>
        <div className="container">
          <div className="row text-center">
            <ServerError />
            <div className="col"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>ycf관리자 - 개인참가신청 추가하기</title>
      </Helmet>
      <MobileNavbar />
      <div className="d-flex">
        <AdminSideBar />
        <div className="contianer mt-5 ms-2">
          <div className="row">
            <ExtraBold name="개인참가신청" />
          </div>
          <IndividualInputForm2 />
        </div>
      </div>
    </div>
  );
}
export default AdminIndividualAddParticipation;
