import React, { useEffect, useState, useRef } from "react";
import AdminSideBar from "components/AdminSideBar";
import ExtraBold from "components/ExtraBold";
import { Helmet } from "react-helmet";
import MobileNavbar from "components/MobileNavbar";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useVerify } from "hooks/useVerify";
import { useNavigate, useLocation } from "react-router-dom";
import { useAxios } from "hooks/useAxios";
import "assets/css/Admin.css";
import Spinner from "components/Spinner";
import FrequentModify from "components/FrequentModify";

function AdminModifyFrequent() {
  const [frequentData, setFrequentData] = useState({
    title: "",
    content: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const titleRef = useRef();
  const editorRef = useRef();
  const { verifyToken } = useVerify();

  const { accessAxios } = useAxios();

  const cancelModify = () => {
    navigate("/admin-frequent");
  };

  const modifingData = async () => {
    const modifedData = {
      title: titleRef.current.value,
      content: editorRef.current?.getInstance().getHTML(),
      id: location.state.id,
    };

    try {
      const result = await accessAxios.post("/api/modify-frequent", {
        ...modifedData,
      });

      if (result.data.resultCode === "00") {
        Swal.fire({
          title: "수정완료",
          text: "자주하는 질문 수정되었습니다..",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        });
        navigate("/admin-frequent");
      } else {
        Swal.fire({
          icon: "error",
          title: "수정하기 실패",
          text: "관리자에게 문의해주세요!!!",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    editorRef.current.getInstance().setHTML(frequentData.content);
  });

  useEffect(() => {
    const fileAccept = [
      "image/jpeg",
      "image/png",
      "image/bmp",
      "image/gif",
      "image/webp",
      "image/svg+xml",
    ];

    const limitVolume = 1024 * 1024 * 3;
    editorRef.current?.getInstance().removeHook("addImageBlobHook");
    editorRef.current
      ?.getInstance()
      .addHook("addImageBlobHook", async (blob, callback) => {
        if (!fileAccept.includes(blob.type)) {
          Swal.fire({
            title: `파일 확장자`,
            text: `해당 파일 확장자는 업로드되지 않습니다.`,
            icon: "error",
            showConfirmButton: true,
          });
        } else if (blob.size > limitVolume) {
          Swal.fire({
            title: `허용 용량 초과`,
            text: `3MB 이하 이미지를 사용해주세요. `,
            icon: "error",
            showConfirmButton: true,
          });
        } else if (blob.size < limitVolume) {
          const formData = new FormData();

          formData.append("image", blob);

          try {
            const result = await accessAxios.post(
              "/api/image-upload",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            const imagePath = result.data.fileName;

            callback(
              process.env.REACT_APP_IMAGE_SERVER_URL + imagePath,
              "image"
            );
          } catch (error) {
            console.log(error);
            if (
              error.code === "ERR_BAD_RESPONSE" ||
              error.code === "ECONNABORTED"
            ) {
              Swal.fire({
                icon: "error",
                title: "서버 에러",
                text: "관리자에게 문의해주세요!!!",
              });
            }
          }
        }
      });
  }, [editorRef]);

  useEffect(() => {
    let ignore = false;
    const fetchingData = async () => {
      try {
        if (!ignore) {
          setLoading(true);
        }
        const result = await accessAxios.post("/api/get-where-frequent", {
          id: location.state.id,
        });

        if (Object.keys(result.data[0]).length > 0) {
          if (!ignore) {
            setLoading(false);
            setFrequentData({
              ...frequentData,
              title: result.data[0]?.FREQUENT_TITLE,
              content: result.data[0]?.FREQUENT_CONTENT,
            });
          }
        } else {
          if (!ignore) {
            setFrequentData({ title: "데이터 불러오기 실패" });
          }
        }
      } catch (error) {
        if (!ignore) {
          setLoading(false);
        }
        console.log(error);
      }
    };
    fetchingData();
    verifyToken();
    return () => {
      ignore = true;
    };
  }, []);

  return (
    <div>
      {loading ? <Spinner /> : <></>}
      <MobileNavbar />
      <div className="d-flex">
        <AdminSideBar />
        <Helmet>
          <title>ycf관리자 - 자주하는 질문 수정</title>
        </Helmet>
        <div className="container mt-5">
          <ExtraBold name="자주하는 질문 수정" />
          <div className="row">
            <div className="col">
              <FrequentModify
                fn={{
                  frequentData,
                  titleRef,
                  editorRef,
                  modifingData,
                  cancelModify,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminModifyFrequent;
