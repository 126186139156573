import React, { useRef, useState, useEffect } from "react";
import AdminSideBar from "components/AdminSideBar";
import MobileNavbar from "components/MobileNavbar";
import ExtraBold from "components/ExtraBold";
import { Helmet } from "react-helmet";
import { useAxios } from "hooks/useAxios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import Spinner from "components/Spinner";
import classnames from "classnames";

function AdminSearch() {
  const [searchList, setSearchList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [competitionData, setCompetitionData] = useState([]);
  const [checkedType, setCheckedType] = useState("individual");
  const { accessAxios } = useAxios();
  const searchInputRef = useRef();

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter") {
      OnClickSearch();
    }
  };

  const OnClickSearch = async () => {
    const phoneNumberRegex =
      /^(01[0-9]{8}|010[0-9]{8}|011[0-9]{7}|016[0-9]{7}|017[0-9]{7}|018[0-9]{7}|019[0-9]{7})$/;
    let apiAddress = "";
    try {
      if (searchInputRef.current.value === "") {
        Swal.fire({
          title: "검색내용을 입력해주세요.",
          text: "입력값이 없습니다.",
          icon: "error",
          showConfirmButton: true,
        });
      } else {
        if (phoneNumberRegex.test(searchInputRef.current.value)) {
          if (checkedType === "individual") {
            apiAddress = "/search/phone-number";
          } else if (checkedType === "group") {
            apiAddress = "/search/group-leader-phone-number";
          }
        } else {
          if (checkedType === "individual") {
            apiAddress = "/search/name";
          } else if (checkedType === "group") {
            apiAddress = "/search/group-leader-name";
          }
        }

        const result = await accessAxios.post(`/api${apiAddress}`, {
          searchText: searchInputRef.current.value,
        });

        if (result.data.resultCode === "02") {
          Swal.fire({
            title: "참가자가 없습니다.",
            text: "참가자 정보가 맞는지 확인해주세요.",
            icon: "error",
            showConfirmButton: true,
          });
        } else {
          if (checkedType === "individual") {
            setSearchList(listMap(result.data));
          } else if (checkedType === "group") {
          }
        }
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "search error",
        text: "관리자에게 문의해주세요.",
        icon: "error",
        showConfirmButton: true,
      });
    }
  };

  const onClickRadioBtnIndividual = () => {
    setCheckedType("individual");
    setSearchList([]);
  };

  const onClickRadioBtnGroup = () => {
    setCheckedType("group");
    setSearchList([]);
  };

  const onClickModify = (e) => {
    console.log(e);
  };

  const onClickDelete = () => {};

  const listMap = (dataList) => {
    const createList = dataList.map((data, index) => (
      <tr key={data.id}>
        <td>{index + 1}</td>
        <td>{data.name}</td>
        <td>{data.phoneNumber}</td>
        <td>{data.gender}</td>
        <td>{data.address}</td>
        <td>{data.detailAddress}</td>
        <td>{data.courseType}</td>
        <td>{data.cycleType}</td>
        <td>{data.createdAt}</td>
        <td>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={() => {
              onClickModify(data);
            }}
          >
            수정
          </button>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={() => {
              onClickDelete(data);
            }}
          >
            삭제
          </button>
        </td>
      </tr>
    ));

    return createList;
  };

  useEffect(() => {
    let ignore = false;

    const competitionData = async () => {
      try {
        if (!ignore) {
          setLoading(true);
        }

        const result = await accessAxios.post("/api/now-competition");
        if (result.data) {
          if (!ignore) {
            setLoading(false);
            setCompetitionData(result.data);
          }
        }
      } catch (error) {
        if (!ignore) {
          setLoading(false);
        }
        console.log(error);
        if (
          error.code === "ERR_BAD_RESPONSE" ||
          error.code === "ECONNABORTED"
        ) {
          Swal.fire({
            icon: "error",
            title: "서버 에러",
            text: "관리자에게 문의해주세요!!!",
          });
        }
      }
    };
    competitionData();

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <>
      {loading ? <Spinner /> : <></>}
      <MobileNavbar />
      <div className="d-flex">
        <Helmet>
          <title>ycf관리자 - 참가자 검색</title>
        </Helmet>
        <AdminSideBar />
        <div className="container-fluid mt-5">
          <ExtraBold name="참가자 검색" />
          <div className="row">
            <div className="col-sm-2">
              <p className="main-font">현재 설정된 대회</p>
            </div>
            <div className="col-sm-6">
              <p>{competitionData[0]?.COMPETITION_INFORMATION_NAME}</p>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-sm-2"></div>
            <div className="col-sm-10">
              <input
                type="radio"
                className="btn-check"
                name="individual"
                id="individual"
                autoComplete="off"
                value="individual"
                onChange={onClickRadioBtnIndividual}
                checked={checkedType === "individual" ? true : false}
              />
              <label
                className={classnames("btn", "bold-font", "me-2", {
                  "btn-outline-secondary":
                    checkedType === "group" ? true : false,
                  "btn-outline-primary":
                    checkedType === "individual" ? true : false,
                })}
                htmlFor="individual"
              >
                개인
              </label>
              <input
                type="radio"
                className="btn-check"
                name="group"
                id="group"
                autoComplete="off"
                value="group"
                onChange={onClickRadioBtnGroup}
                checked={checkedType === "group" ? true : false}
              />
              <label
                className={classnames("btn", "bold-font", {
                  "btn-outline-secondary":
                    checkedType === "individual" ? true : false,
                  "btn-outline-primary": checkedType === "group" ? true : false,
                })}
                htmlFor="group"
              >
                단체
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-6">
              <div className="d-flex">
                <input
                  className="form-control form-control-sm"
                  type="text"
                  placeholder="이름 or 전화번호를 입력해주세요."
                  aria-label=".form-control-sm example"
                  ref={searchInputRef}
                  onKeyDown={handleOnKeyPress}
                />
                <button
                  type="button"
                  className="btn btn-outline-primary ms-2"
                  style={{ width: "65px" }}
                  onClick={OnClickSearch}
                >
                  검색
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <table
                className="table main-font mt-3 admin-table"
                style={{ textAlign: "center" }}
              >
                <thead>
                  {checkedType === "individual" ? (
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">이름</th>
                      <th scope="col">전화번호</th>
                      <th scope="col">성별</th>
                      <th scope="col">주소</th>
                      <th scope="col">상세주소</th>
                      <th scope="col">코스종류</th>
                      <th scope="col">구분</th>
                      <th scope="col">신청일</th>
                    </tr>
                  ) : (
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">단체명</th>
                      <th scope="col">단체식별번호</th>
                      <th scope="col">성별</th>
                      <th scope="col">주소</th>
                      <th scope="col">상세주소</th>
                      <th scope="col">코스종류</th>
                      <th scope="col">구분</th>
                      <th scope="col">신청일</th>
                    </tr>
                  )}
                </thead>
                <tbody className="table-group-divider">{searchList}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminSearch;
